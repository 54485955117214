var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getTerminalSurvey,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.length-1].id)+" ")]}},{key:"item.serie",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.length-1].id_terminal)+" ")]}},{key:"item.encuesta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.length-1].serv_survey.srv_name)+" ")]}},{key:"item.sede",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.length-1].serv_survey.serv_area_headquarter.serv_headquarter.hdq_name)+" ")]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[item.length-1].serv_survey.serv_area_headquarter.serv_area.ars_name)+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }